import React from "react"
import styled from 'styled-components'

const GridItem = ({order, align, children }) => {
  if(!align){
    align = "left";
  }
  return (
    <>
        <Item align={align} order={order}>
            {children}
        </Item>
    </>
  )
} 

export default GridItem

const Item = styled.div`
  text-align: ${props => props.align};
  @media (max-width: 800px) {
    text-align: left;
    grid-row: ${props => props.order}
  }  
`;
