import React from "react"
import styled from 'styled-components'

const IconButton = ({children, size, padding, onClick }) => {
  if(!size) size = "30px";
  if(!padding) padding = "12px";
  return (
    <IconBtn onClick={onClick} padding={padding} size={size}>{children}</IconBtn>
  )
} 
export default IconButton

const IconBtn = styled.div`
    display: inline-block;
    padding:${props => props.padding};
    width: ${props => props.size};
    height: ${props => props.size};
    cursor: pointer;
    border-radius: 50%;
    overflow: visible;

    &:hover {
      background-color: rgba(0,0,0,0.10);
      @media (hover: none): {
        background-color: transparent;
      }
    },
`;
